// extracted by mini-css-extract-plugin
export var column = "LocationsDashboardOptions__column__M0LUX";
export var flex = "LocationsDashboardOptions__flex__nRPHo";
export var flexColumn = "LocationsDashboardOptions__flexColumn__agiuj";
export var gap1 = "LocationsDashboardOptions__gap1__em6tD";
export var gap2 = "LocationsDashboardOptions__gap2__otTec";
export var gap3 = "LocationsDashboardOptions__gap3__Y6Lev";
export var gap4 = "LocationsDashboardOptions__gap4__ezzwm";
export var gap5 = "LocationsDashboardOptions__gap5__YduNr";
export var popoverBody = "LocationsDashboardOptions__popoverBody__hUvZw";
export var popoverBodyLink = "LocationsDashboardOptions__popoverBodyLink__eGQ1Z";
export var popoverBodyLinkIcon = "LocationsDashboardOptions__popoverBodyLinkIcon__dL0s2";
export var row = "LocationsDashboardOptions__row__UeKNN";